import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-b59136b0"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "content"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "btn-group"
};
var _hoisted_4 = {
  class: "back-btn"
};
var _hoisted_5 = {
  class: "main-btn"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_header_component = _resolveComponent("header-component");

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_router_link = _resolveComponent("router-link");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_header_component), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('loading.error_non_displayable_try_reloading_the_page')), 1), _createVNode(_component_svg_icon, {
    class: "payment-icon",
    iconName: "error_image"
  }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_router_link, {
    class: "link",
    to: "/"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.t('loading.home')), 1)];
    }),
    _: 1
  }), _createElementVNode("a", {
    class: "link",
    onClick: _cache[0] || (_cache[0] = //@ts-ignore
    function () {
      return _ctx.reload && _ctx.reload.apply(_ctx, arguments);
    })
  }, [_createElementVNode("button", _hoisted_5, _toDisplayString(_ctx.t('loading.reload')), 1)])])])], 64);
}