import { defineComponent, inject, onMounted } from 'vue';
import Header from '@/components/Header.vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'NotFound',
  components: {
    HeaderComponent: Header,
    SvgIcon: SvgIcon
  },
  setup: function setup() {
    var t = inject('t');
    onMounted(function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('meta[name="prerender-status-code"]').setAttribute('content', 404);
    });

    var reload = function reload() {
      location.reload();
    };

    return {
      t: t,
      reload: reload
    };
  }
});